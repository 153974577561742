import 'bootstrap/dist/css/bootstrap.css';
import 'less/main.less';
import 'bootstrap';
import $ from 'jquery';

$(function() {
    // Change language
    function updateUrlParameter(uri, key, value) {
        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? '' : uri.substr(i);
        uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        var separator = uri.indexOf('?') !== -1 ? '&' : '?';
        if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + '=' + value + '$2');
        } else {
            uri = uri + separator + key + '=' + value;
        }
        return uri + hash;
    }

    $('.footer__lang-name .dropdown-menu li').click(function(e) {
        var langText = $(e.currentTarget).text(),
            lang = langText === 'english' ? 'en' : 'ru';
        window.location.replace(updateUrlParameter(window.location.href, 'lang', lang));
    });

    $('.dropdown').on('show.bs.dropdown', function(e) {
        $('.dropdown-menu', e.currentTarget).first().stop(true, true).slideDown();
    });

    $('.dropdown').on('hide.bs.dropdown', function(e) {
        $('.dropdown-menu', e.currentTarget).first().stop(true, true).slideUp();
    });
});